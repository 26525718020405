import { Button } from '@benjaminpetry/sisterhoodmassagen-design'
import './VoucherPage.scss'

export const VoucherPage = () => {
  return (
    <div className="c-voucherpage">
      <header className="c-voucherpage__header">
        <h2>Sisterhood Massagen</h2>
        <h3>Gutscheine</h3>
      </header>
      <div className='c-voucherpage__scroll-container'>
        <main className='c-voucherpage__container'>
          <div>Einen Gutschein bekommst du aktuell wie folgt:</div>
          <ol>
            <li>Schreibe mir eine Nachricht an info@sisterhoodmassagen.de mit dem Wunschbetrag für deinen Gutschein.</li>
            <li>Du erhälst von mir innerhalb von 24 Stunden einen Gutschein samt Rechnung.</li>
            <li>Überweise den Rechnungsbetrag und der Gutschein wird aktiviert.</li>
            <li>Freue dich darauf, jemandem mit dem Gutschein eine besondere Freude zu machen.</li>
          </ol>
          <Button>Gutschein anfragen</Button>
        </main>
      </div>
    </div>
  )
}
