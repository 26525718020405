import { MassageDatabase } from './massages'

export enum Weekday {
  Monday = 'Montag',
  Tuesday = 'Dienstag',
  Wednesday = 'Mittwoch',
  Thursday = 'Donnerstag',
  Friday = 'Freitag',
  Saturday = 'Samstag',
  Sunday = 'Sonntag'
}

export const Weekdays = Object.values(Weekday)

export const Texts = {
  Welcome: {
    Title: 'Herzlich Willkommen',
    Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna'
  },
  Promotion: {
    Title: 'Willkommensgruß'
  },
  Massages: {
    Title: 'Meine Leistungen',
    Images: [
      { name: 'Massage-Back', alt: 'Zwei Hände massieren den Rücken einer Frau.' },
      { name: 'Massage-Back', alt: 'Zwei Hände massieren den Rücken einer Frau.' },
      { name: 'Massage-Back', alt: 'Zwei Hände massieren den Rücken einer Frau.' }
    ],
    Offer: MassageDatabase
  },
  OpeningHours: {
    Title: 'Meine Praxiszeiten',
    Hours: {
      [Weekday.Monday]: '18:00 - 21:00',
      [Weekday.Tuesday]: '10:00 - 13:00',
      [Weekday.Wednesday]: '-',
      [Weekday.Thursday]: '16:00 - 19:00',
      [Weekday.Friday]: '16:00 - 21:00',
      [Weekday.Saturday]: '09:30 - 19:30',
      [Weekday.Sunday]: '-'
    },
    Hint: 'Wenn du aktute Schmerzen hast und keinen Termin findest, melde dich gerne direkt bei mir und wir finden eine Lösung.'
  },
  Rooms: {
    Title: 'Meine Räumlichkeiten',
    Images: [
      { name: 'example-room-2-640px', alt: 'Der Empfangstisch der Hebammenpraxis mit einem Bilderrahmen auf dem "Hebammenpraxis in Bargteheide" steht und die Siluette eines Storches mit einem Bündel im Schnabel abgebildet ist.' },
      { name: 'example-room-1-640px', alt: 'Ein hell erleuchteter Raum mit einer Massageliege.' },
      { name: 'example-room-3-640px', alt: 'Ein Tisch mit zwei Stühlen. Auf dem Tisch steht ein Bilderrahmen welcher zwei Frauen in Form eines Schmetterlings darstellt.' }
    ]
  },
  Location: {
    Title: 'Hier findest du mich',
    Map: {
      Alt: 'GoogleMaps Karte, die aus der Vogelperspektive zeigt, dass man über die Kreuzung geradeaus auf den Parkplatz fahren muss und am Ende links die Hebammenpraxis findet.',
      Caption: 'Ich massiere in der Hebammenpraxis Bargteheide in der Heinrich-Hertz-Straße.'
    },
    Driveway: {
      Alt: 'Das Bild zeigt die Einfahrt zum Parkplatz, welcher direkt von der Kreuzung geradeaus zu erreichen ist.',
      Caption: 'Du findest mich am Ende diesen Weges.'
    },
    Address: {
      Title: 'Adresse',
      Name: 'Hebammenpraxis Bargteheide',
      Street: 'Heinrich-Hertz-Straße 23a',
      City: 'Bargteheide',
      PostalCode: '22941',
      GoogleMaps: 'https://maps.app.goo.gl/WEwknsGG394VDrnG9'
    },
    Benefits: {
      Title: 'Entspannt ankommen'
    }
  }
}
