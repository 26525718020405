import { ReactNode, useEffect, useRef } from 'react'
import { ListOfMassages, MassageDatabase } from '../data/massages'
import { MassageImageGallery } from './Massages/MassageImageGallery'
import { MassageSection } from './Massages/MassageSection'
import './MassagesPage.scss'
import { Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { useLocation } from 'react-router-dom'

export const MassagesPage = () => {
  const location = useLocation()
  const lastHash = useRef('')

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1)
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        lastHash.current = ''
      }, 100)
    }
  }, [location])

  const renderSections = () => {
    const result: Array<ReactNode> = []
    ListOfMassages.forEach((key, index) => {
      result.push(<MassageSection key={key} {...(MassageDatabase[key])} ></MassageSection>)
      if (index < ListOfMassages.length - 1) {
        result.push(<Separator sizeAppearance='small' key={key + 'separator'} className='c-massages-page__separator'></Separator>)
      }
    })
    return result
  }
  return (
    <div className="c-massages-page" id='top'>
      <header className="c-massages-page__header">
        <MassageImageGallery></MassageImageGallery>
      </header>
      <main className='c-massages-page__main'>
        {renderSections()}
      </main>
    </div>
  )
}
