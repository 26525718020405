import { Botch, BotchType } from '@benjaminpetry/sisterhoodmassagen-design'
import './ImpressumPage.scss'

export const ImpressumPage = () => {
  return (
    <main className="c-impressum-page">
      <section className='c-impressum-page__header'>
        <Botch className='c-impressum-page__header-botch' botchType={BotchType.BluePurple3}></Botch>
        <h2>Impressum</h2>
      </section>
      <section>
        <h3>Angaben gemäß § 5 TMG</h3>
        <p>Isabelle Petry</p>
        <p>Marie-Schlei-Weg 6<br />22941 Bargteheide</p>
      </section>
      <section className='c-impressum-page__contact'>
        <h3>Kontakt</h3>
        <p>Telefon: 0176 61 31 95 73</p>
        <p>E-Mail: <a href="mailto:info@sisterhoodmassagen.de">info@sisterhoodmassagen.de</a></p>
      </section>
      <section>
        <h3>Haftung für Inhalte</h3>
        <p>Als Diensteanbieterin bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieterin jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.</p>
      </section>
      <section className='c-impressum-page__disclaimer-links'>
        <Botch className='c-impressum-page__disclaimer-links-botch' botchType={BotchType.OrangePink}></Botch>
        <h3>Haftung für Links</h3>
        <p>Mein Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.</p>
      </section>
    </main>
  )
}
