import { Botch, BotchType, Photography } from '@benjaminpetry/sisterhoodmassagen-design'
import './Welcome.scss'
import { Texts } from '../../data/texts'

export const Welcome = () => {
  return (
    <section className="c-welcome">
      <Photography className='c-welcome__photography' eager={true} basename='Consulting-1' alt='Ich begrüße eine Kundin in meinen Räumlichkeiten.'></Photography>
      <div className='c-welcome__text-container'>
        <Botch className='c-welcome__botch' botchType={BotchType.OrangeGreen}></Botch>
        <h2 className='c-welcome__title'>{Texts.Welcome.Title}</h2>
        <p className='c-welcome__text'>{Texts.Welcome.Text}</p>
      </div>
    </section>
  )
}
