import { Botch, BotchType, Illustration, IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Promotion.scss'

export const Promotion = () => {
  return (
    <section className="c-promotion">
      <Botch className='c-promotion__botch' botchType={BotchType.BluePurple3}></Botch>
      <div className='c-promotion__wrapper'>
        <div className='c-promotion__content'>
          <h3 className='c-promotion__title'>{Texts.Promotion.Title}</h3>
          <div className='c-promotion__text'>
            <div className='c-promotion__pre'>Ich biete dir als Neukundin</div>
            <div className='c-promotion__highlight'>einen Rabatt von 10 Euro</div>
            <div className='c-promotion__post'>auf deine <u>erste</u> Massage bei mir an. So kannst du kostengünstig ausprobieren, ob dir meine Arbeit gut tut.</div>
          </div>
          <div className='c-promotion__instructions'>Schreibe einfach: &quot;Hi-Sister!&quot; in das Kommentar-Feld in meinem Buchungstool.</div>
          <div className='c-promotion__instructions'>Ich freue mich auf dich!</div>
        </div>
        <div className='c-promotion__illustration-container'>
          <Illustration className='c-promotion__illustration' illustration={IllustrationType.Sisterhood}></Illustration>
        </div>
        {/* <Photography basename='Massage-Back' alt='Zwei Hände die den Rücken massieren'></Photography> */}
      </div>
    </section>
  )
}
