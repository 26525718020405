import { RouteObject } from 'react-router-dom'
import { AgreementPage } from './standalone/AgreementPage'
import { VoucherPage } from './standalone/VoucherPage'
import { AboutMePage } from './AboutMePage'
import { MassagesPage } from './MassagesPage'
import { ContactPage } from './ContactPage'
import { ImpressumPage } from './standalone/ImpressumPage'
import { AgbPage } from './standalone/AgbPage'
import { HomePage } from './HomePage'

export const AppRoutes = {
  Home: () => '/',
  Massages: (specificMassage?: string) => `/massagen${specificMassage ? `#${specificMassage}` : ''}`,
  AboutMe: () => '/uebermich',
  Contact: () => '/kontakt',
  Impressum: () => '/impressum',
  AGBs: () => '/agb',
  Agreement: () => '/einverstaendnis',
  Voucher: () => '/gutschein'
}

export const routes: RouteObject[] = [
  {
    path: AppRoutes.Home(),
    element: <HomePage />,
    children: [
      {
        index: true,
        element: <HomePage />
      }
    ]
  },
  {
    path: AppRoutes.Massages(),
    element: <MassagesPage></MassagesPage>
  },
  {
    path: AppRoutes.AboutMe(),
    element: <AboutMePage></AboutMePage>
  },
  {
    path: AppRoutes.Contact(),
    element: <ContactPage></ContactPage>
  },
  {
    path: AppRoutes.Impressum(),
    element: <ImpressumPage></ImpressumPage>
  },
  {
    path: AppRoutes.AGBs(),
    element: <AgbPage></AgbPage>
  },
  {
    path: AppRoutes.Agreement(),
    element: <AgreementPage />
  },
  {
    path: AppRoutes.Voucher(),
    element: <VoucherPage />
  }
]
