import { CalendlyButton } from '@benjaminpetry/sisterhoodmassagen-design'
import { ListOfMassages, MassageDatabase } from '../../data/massages'
import { Texts } from '../../data/texts'
import './Massages.scss'
import { MassageCard } from '../Massages/MassageCard'

export const Massages = () => {
  return (
    <section className="c-massages">
      <h2 className='c-massages__title'>{Texts.Massages.Title}</h2>
      <div className='c-massages__container'>
        {ListOfMassages.map((key) => <div key={key} className='c-massages__card-container'><div className='c-massages__card-background c-mobile-only'></div><MassageCard className='c-massages__card' {...(MassageDatabase[key])} /></div>)}
        <div className='c-massages__card-background c-desktop-only'>
        <CalendlyButton className="c-massages__calendly-button"></CalendlyButton>
        </div>
      </div>
      <CalendlyButton className="c-massages__calendly-button c-mobile-only"></CalendlyButton>
    </section>
  )
}
