import { Botch, BotchType, CalendlyButton, Logo, LogoType } from '@benjaminpetry/sisterhoodmassagen-design'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../pages/router'
import './Menu.scss'
import { MenuButton } from './MenuButton'
import { MenuItem } from './MenuItem'

export const Menu = () => {
  const navigate = useNavigate()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const toggleMobileMenuVisibility = () => {
    setMobileMenuVisible(!mobileMenuVisible)
  }

  const hideMobileMenu = () => {
    setMobileMenuVisible(false)
  }

  return (
    <header className={`c-menu ${mobileMenuVisible ? 'c-menu--mobile-menu-visible' : ''}`}>
        <div className="c-menu__logo-name-container" onClick={() => { navigate(AppRoutes.Home()); hideMobileMenu() }}>
          <Logo className='c-menu__logo' eager={true} logoType={LogoType.Default}></Logo>
          <div className='c-menu__name'>Sisterhood Massagen</div>
        </div>
        <div className='c-menu__items'>
            <nav className='c-menu__items-container'>
              <MenuItem url={AppRoutes.Home()} onClick={hideMobileMenu}>Home</MenuItem>
              <MenuItem url={AppRoutes.Massages()} onClick={hideMobileMenu}>Massagen</MenuItem>
              <MenuItem url={AppRoutes.AboutMe()} onClick={hideMobileMenu}>Über mich</MenuItem>
              <MenuItem url={AppRoutes.Contact()} onClick={hideMobileMenu}>Kontakt</MenuItem>
            </nav>
            <Botch className="c-menu__botch-1 c-menu-mobile-only" botchType={BotchType.YellowPurple}></Botch>
            <Botch className="c-menu__botch-2 c-menu-mobile-only" botchType={BotchType.OrangeGreen}></Botch>
            <Botch className="c-menu__botch-3 c-menu-mobile-only" botchType={BotchType.BluePurple2}></Botch>
            <CalendlyButton className="c-menu__calendly-button c-menu-mobile-only"></CalendlyButton>
        </div>
        <CalendlyButton className="c-menu__calendly-button c-menu-desktop-only"></CalendlyButton>
        <MenuButton open={mobileMenuVisible} onClick={toggleMobileMenuVisibility} className="c-menu__mobile-menu-button c-menu-mobile-only"></MenuButton>
    </header>
  )
}
