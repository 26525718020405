import { Assets, Button, Illustration, Image, SvgImage, openLink } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Location.scss'
import { Benefit, ListOfBenefits, BenefitDatabase } from '../../data/benefits'

const { Title, Map, Driveway, Address } = Texts.Location

export const Location = () => {
  const map = Assets.folder('location').file('Map.svg')
  const driveWay = Assets.folder('location').imageSet('Driveway', Driveway.Alt)

  const renderBenefit = (benefit: Benefit) => {
    const { illustration, description } = BenefitDatabase[benefit]
    return (
      <div className='c-location__benefit' key={benefit}>
        <Illustration className='c-location__benefit-illustration' illustration={illustration}></Illustration>
        <p className='c-location__benefit-description'>{description}</p>
      </div>
    )
  }

  return (
    <section className="c-location">
      <h2 className='c-location__title'>{Title}</h2>
      <div className='c-location__map-and-driveway-container'>
        <figure className='c-location__map-container'>
          <SvgImage className='c-location__map' src={map} ratio={[1, 1]} alt={Map.Alt}></SvgImage>
          <figcaption className='c-location__map-caption'>{Map.Caption}</figcaption>
        </figure>
        <figure className='c-location__driveway-container'>
          <Image className='c-location__driveway' {...driveWay} ratio={[4, 3]}></Image>
          <figcaption className='c-location__driveway-caption'>{Driveway.Caption}</figcaption>
        </figure>
      </div>
      <div className='c-location__address-container'>
        <h3 className='c-location__address-title'>{Address.Title}</h3>
        <div className='c-location__address'>
          <p>{Address.Name}</p>
          <p>{Address.Street}</p>
          <p>{Address.PostalCode} {Address.City}</p>
        </div>
        <Button classtype='secondary' size='small' className='c-location__address-button' onClick={() => openLink(Address.GoogleMaps)}>In GoogleMaps Öffnen</Button>
      </div>
      <div className='c-location__benefits'>
        <div className='c-location__benefits-list'>
          {ListOfBenefits.map(renderBenefit)}
        </div>
      </div>
    </section>
  )
}
