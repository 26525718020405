import { useRoutes } from 'react-router-dom'
import './App.scss'
import { routes } from './pages/router'
import { Menu } from './shared/components/menu/Menu'
import { Footer } from './shared/components/footer/Footer'

export const App = () => {
  const routesElement = useRoutes(routes)

  return (
    <div className="c-app">
      <Menu></Menu>
      <div className='c-app__content'>
        <div className="c-app__main-wrapper">
          {routesElement}
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}
