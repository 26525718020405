import { Assets, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './Room.scss'

const { Title, Images } = Texts.Rooms

export const Room = () => {
  const images = Images.map(data => {
    return Assets.folder('room').imageSet(data.name, data.alt)
  })

  return (
    <section className="c-room">
      <h2 className='c-room__title'>{Title}</h2>
      <div className='c-room__image-container'>
        {images.map((img, index) => {
          return <Image key={index} {...img} className='c-room__image' width={640} ratio={[3, 2]}></Image>
        })}
      </div>
    </section>
  )
}
