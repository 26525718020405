import { Assets, Image } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './MassageImageGallery.scss'

const { Images } = Texts.Massages

export const MassageImageGallery = () => {
  const images = Images.map(data => {
    return Assets.folder('photography').imageSet(data.name, data.alt)
  })

  return (
    <section className="c-massage-image-gallery">
        {images.map((img, index) => {
          return <Image key={index} {...img} className='c-massage-image-gallery__image' width={640} ratio={[3, 2]} eager={true}></Image>
        })}
    </section>
  )
}
