import { ButtonHTMLAttributes, ReactNode } from 'react'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import './MenuItem.scss'

interface MenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url: string;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const MenuItem = ({ url, className, children, onClick, ...rest }: MenuItemProps) => {
  const navigate = useNavigate()
  const resolvedPath = useResolvedPath(url)
  const pathMatch = useMatch({ path: resolvedPath.pathname, end: true })
  const active = pathMatch !== null

  const navigateToUrl = () => {
    navigate(url)
    if (onClick) {
      onClick()
    }
  }

  return (
    <button className={`c-menu-item c-menu-item--${active ? 'active' : 'inactive'} ${className ?? ''}`} onClick={navigateToUrl} {...rest}>
      {children}
    </button>
  )
}
