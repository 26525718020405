import { ButtonHTMLAttributes, MouseEventHandler } from 'react'
import './MenuButton.scss'

interface MenuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  open: boolean;
  className: string;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const MenuButton = ({ open, className, onClick, ...rest }: MenuButtonProps) => {
  return (
    <button className={`c-menu-button c-menu-button--${open ? 'open' : 'closed'} ${className ?? ''}`} onClick={onClick} aria-label='Open menu' {...rest}>
        <div className='c-menu-button__container'>
            <div className='c-menu-button__bar c-menu-button__bar--top'></div>
            <div className='c-menu-button__bar c-menu-button__bar--middle'></div>
            <div className='c-menu-button__bar c-menu-button__bar--bottom'></div>
        </div>
    </button>
  )
}
