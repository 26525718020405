import { IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'

export enum Benefit {
  Parking = 'Parking',
  Towel = 'Towel',
  Sustainability = 'Sustainability',
  Accessibility = 'Accessibility',
}

export const ListOfBenefits = [Benefit.Parking, Benefit.Towel, Benefit.Sustainability, Benefit.Accessibility]

export interface BenefitData {
  illustration: IllustrationType
  description: string
}

export const BenefitDatabase: Record<Benefit, BenefitData> = {
  [Benefit.Parking]: {
    illustration: IllustrationType.Car,
    description: 'Vor der Praxis stehen dir Parkmöglichkeiten zur Verfügung.'
  },
  [Benefit.Towel]: {
    illustration: IllustrationType.Towel,
    description: 'Hier ist alles für deine Entspannung vorbereitet. Du brauchst nichts mitbringen.'
  },
  [Benefit.Sustainability]: {
    illustration: IllustrationType.Flower,
    description: 'Ich verwende ausschließlich umweltfreundliche Produkte, die deine Haut und die Erde gleichermaßen schätzen.'
  },
  [Benefit.Accessibility]: {
    illustration: IllustrationType.Wheelchair,
    description: 'Ich begrüße dich in einer barrierefreien Praxis, damit Entspannung für jede Frau erreichbar ist.'
  }
}
