import { BotchType, IllustrationType } from '@benjaminpetry/sisterhoodmassagen-design'

export enum Massage {
    Wellness = 'Wellness',
    Pregnancy = 'Pregnancy',
    Postnatal = 'Postnatal',
}

export const ListOfMassages = [Massage.Wellness, Massage.Pregnancy, Massage.Postnatal]

export interface MassageData {
    name: string
    illustration: IllustrationType
    shortDescription: string
    description: string
    effectDescription: Array<string>
    prices: Array<string>
    anchor: string
    additionalBotch: BotchType
}

export const MassageDatabase: Record<Massage, MassageData> = {
  [Massage.Wellness]: {
    name: 'Wellnessmassage',
    illustration: IllustrationType.Wellness,
    shortDescription: 'Ob Ganz- oder Teilkörpermassage und von sanft und ruhig bis dynamisch und kräftig, wir finden gemeinsam heraus, was du gerade brauchst.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do ',
    effectDescription: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli'
    ],
    prices: ['1,- Euro pro Minute,', 'ab 45 Minuten'],
    anchor: 'wellness',
    additionalBotch: BotchType.BluePurple1
  },
  [Massage.Pregnancy]: {
    name: 'Schwangerschaftsmassage',
    illustration: IllustrationType.Pregnancy,
    shortDescription: 'Eine Ganzkörpermassage in bequemer Seitenlage, die gezielt auf die besonderen Bedürfnisse in der Schwangerschaft eingeht. Sie fördert Entspannung und lindert Beschwerden. Die Massage ist ab der 16. SSW möglich.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do ',
    effectDescription: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli'
    ],
    prices: ['65,- Euro für 60 Minuten', '80,- Euro für 75 Minuten'],
    anchor: 'schwangerschaft',
    additionalBotch: BotchType.BlueOrange
  },
  [Massage.Postnatal]: {
    name: 'Wochenbett- und Rückbildungsmassage',
    illustration: IllustrationType.Postnatal,
    shortDescription: 'In dieser freudigen, aber auch besonders herausfordernden Zeit, bietet die postnatale Massage eine wertvolle Unterstützung für die körperliche Regeneration und die seelische Zentrierung der frischgebackenen Mutter.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do ',
    effectDescription: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
      'Lorem ipsum dolor sit amet, consectetur adipiscing eli'
    ],
    prices: ['65,- Euro für 60 Minuten', '80,- Euro für 75 Minuten'],
    anchor: 'postnatal',
    additionalBotch: BotchType.BluePurple3
  }
}
